import { Button, CardActions, Typography } from '@mui/material';
import React from 'react';
import './Header.css';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import imagem from './images/6.png';


function Header() {
    return (
        <header style={{ backgroundColor: "#001f3a" }}>
            <nav className="top">
                <div>
                    <img width={80} src={imagem}/>
                </div>
                <div className="navigation">
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><a href="/#sobre">Sobre nós</a></li>
                        <li><a href="/#servicos">Serviços</a></li>
                        <li><a href="/#como-funciona">Como Funciona</a></li>
                        <li><a href="/#projetos">Projetos</a></li>
                        <li><a href="/#contato">Contato</a></li>
                    </ul>
                </div>
            </nav>
            <div className="texto-principal">
                <h1 style={{ textTransform: 'uppercase' }}>A software house 4.0 que está revolucionando o mercado digital!</h1>
                <div className='button-div'>
                    <Button className="fale-conosco" href='https://wa.me/+558381581045' target="_blank" style={{ textTransform: 'none' }} variant="contained">Fale Conosco</Button>
                </div>
            </div>
            <div className="caixas">
                <div>
                    <Card sx={{ width: 400 }} className="card1">
                        <CardContent>
                            <Typography variant="h5" component="div" className='porcentagem'>
                                300%
                            </Typography>
                            <Typography variant="h5" component="div" style={{ fontWeight: 'bolder' }}>
                                Mais rápido para lançar
                            </Typography>
                            <Typography variant="body2" className="card-descricao">
                                Velocidade é muito importante, por isso lançamos o seu App em até 90 dias!
                            </Typography>
                        </CardContent>
                    </Card>
                </div>
                <div>
                    <Card sx={{ width: 400, borderBottomColor: '#019875' }} className="card1">
                        <CardContent>
                            <Typography variant="h5" component="div" className='porcentagem'>
                                100%
                            </Typography>
                            <Typography variant="h5" component="div" style={{ fontWeight: 'bolder' }}>
                                Dentro do seu orçamento
                            </Typography>
                            <Typography variant="body2" className="card-descricao">
                                A metodologia Fraktal garante a mais alta qualidade técnica e custos acessíveis, para que a sua ideia saia do papel!
                            </Typography>
                        </CardContent>
                    </Card>
                </div>
                <div>
                    <Card sx={{ width: 400, marginRight: 0, borderBottomColor: '#019875' }} className="card1">
                        <CardContent>
                            <Typography variant="h5" component="div" className='porcentagem'>
                                80%
                            </Typography>
                            <Typography variant="h5" component="div" style={{ fontWeight: 'bolder' }}>
                                Menos custos de sustentação
                            </Typography>
                            <Typography variant="body2" className="card-descricao">
                                Eficiência e velocidade com equipes 80% menores, para que você continue evoluindo o seu sistema de forma continuada.
                            </Typography>
                        </CardContent>
                    </Card>
                </div>
            </div>
        </header >
    );
}

export default Header;
