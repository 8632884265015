import React from 'react';
import './Footer2.css';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

function Footer2() {
    return (
        <div style={{ backgroundColor: '#019875', color: 'white' }}>
            <div className="footer2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className="footer2-textos">
                    <h1>
                        Fraktal
                    </h1>
                    <h2>
                        Somos uma Software House 4.0 que através de uma metodologia ágil e times especializados no conceito LowCode, opera as melhores práticas de desenvolvimento de softwares do mercado, para tirar o seu projeto do papel.
                    </h2>
                </div>
                <div style={{ marginLeft: 25 }} className="footer2-textos">
                    <h1>
                        Links Rápidos
                    </h1>
                    <h2><a href="/">Home</a></h2>
                    <h2><a href="/#sobre">Sobre nós</a></h2>
                    <h2><a href="/#servicos">Serviços</a></h2>
                    <h2><a href="/#como-funciona">Como Funciona</a></h2>
                    <h2><a href="/#cases">Cases</a></h2>
                    <h2><a href="/#contato">Contato</a></h2>
                </div>
                <div className="footer2-textos">
                    <h1>
                        Nossas Redes
                    </h1>
                    <h2 style={{ display: 'flex', alignItems: 'center' }}><LinkedInIcon sx={{ marginRight: 1 }} /> Linkedin</h2>
                    <a href='https://www.instagram.com/acutistecnologia/' target="_blank"><h2 style={{ display: 'flex', alignItems: 'center' }}><InstagramIcon sx={{ marginRight: 1 }} /> Instagram</h2></a>
                    <h1>
                        Outros Links
                    </h1>
                    <h2>Política de Privacidade</h2>
                    <h2>Política de Cookies</h2>
                </div>
            </div>
            <div style={{ textAlign: 'center', fontSize: 9, fontWeight: 'normal', paddingBottom: 15 }}>
                <h1 style={{ fontWeight: 'normal' }}>© 2024 Fraktal. All right reserved. - CNPJ: 38.028.607/0001-52 - Chat: +55 83 98158-1045</h1>
            </div>
        </div>
    );
}

export default Footer2;