import React, { useState, useEffect } from 'react';
import './Body5.css';
import caio from './images/Caio.png';
import caue from './images/Caue.png';
import { Button } from '@mui/material';
import imagem1 from './images/Captura de tela 2.png'
import imagem2 from './images/Captura de tela 4.png'
import imagem3 from './images/Captura de tela 2024-04-29 104806.png'
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import reactjs from './images/React.webp';
import nodejs from './images/png-clipart-node-js-logo-node-js-javascript-web-application-express-js-computer-software-others-miscellaneous-text-thumbnail.png';
import openai from './images/1681142315open-ai-logo.webp';
import js from './images/javascript-logo-8892AEFCAC-seeklogo.com.png';
import myqsl from './images/png-transparent-mysql-php-database-javascript-ajax-carnifex-blue-text-logo.png';
import typescript from './images/png-transparent-typescript-plain-logo-icon-thumbnail.png';
import ios from './images/png-clipart-apple-logo-iphone-apple-logo-ios-7-apple-logo-angle-white.png';
import Slider from './Slider';


const Body5 = () => {

    const images = [imagem1, imagem2, imagem3];

    return (
        <div id="projetos" style={{ marginTop: 150, marginBottom: 50, display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
            <div>
                <div className='projetos'>
                    <h1>Nossos Projetos</h1>
                    <h2>Estes são alguns dos casos significativos que nosso time na Acutis Tecnologia tem trabalhado incansavelmente. Estamos entusiasmados em compartilhá-los com você para que possa ter uma visão mais aprofundada do nosso compromisso com o desenvolvimento tecnológico e inovação na Acutis.</h2>
                    <Slider images={images} />
                </div>
                <div className="comunidade">
                    <h1 style={{ marginBottom: 40 }}>Comunidade Acutis Tecnologia</h1>
                    <div style={{ display: 'flex' }}>
                        <div className="funcao">
                            <div className="imagem-container">
                                <img width={400} src={caio} className='funcao-img' />
                                <div className="overlay"></div>
                            </div>
                            <h1>Caio Henrique</h1>
                            <h2>CEO</h2>
                            <h3>in<a href='https://br.linkedin.com/'>Linkedin</a></h3>
                        </div>
                        <div className="funcao">
                            <div className="imagem-container">
                                <img width={400} src={caue} className='funcao-img' />
                                <div className="overlay"></div>
                            </div>
                            <h1>Cauê Henrique</h1>
                            <h2>CTO</h2>
                            <h3>in<a href='https://br.linkedin.com/'>Linkedin</a></h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ideia-do-papel">
                <h1>Pronto para tirar a sua ideia do papel?</h1>
                <h2>Agende um bate papo com a nossa equipe, estamos prontos para o seu desafio!</h2>
                <Button className="agende-uma-reuniao1" href='https://wa.me/+558381581045' target="_blank" style={{ textTransform: 'none' }} variant="contained">Agende uma reunião</Button>
            </div>
            <div className="tecnologias-parceiras">
                <div className="tecnologias-parceiras-texto">
                    <h1>Tecnologias Parceiras</h1>
                </div>
                <div className="tecnologias">
                    <div className="tecnologias-img">
                        <img width={100} src={js} />
                    </div>
                    <div className="tecnologias-img">
                        <img width={100} src={nodejs} />
                    </div>
                    <div className="tecnologias-img">
                        <img width={100} src={openai} />
                    </div>
                    <div className="tecnologias-img">
                        <img width={100} src={reactjs} />
                    </div>
                    <div className="tecnologias-img">
                        <img width={100} src={myqsl} />
                    </div>
                    <div className="tecnologias-img">
                        <img width={100} src={typescript} />
                    </div>
                    <div className="tecnologias-img">
                        <img width={100} src={ios} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Body5;
