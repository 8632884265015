import React from 'react';
import './Body3.css';
import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined';
import DrawOutlinedIcon from '@mui/icons-material/DrawOutlined';
import CodeIcon from '@mui/icons-material/Code';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import AppSettingsAltOutlinedIcon from '@mui/icons-material/AppSettingsAltOutlined';
import { Button } from '@mui/material';
import imagem from './images/Ativo 3.png';


function Body3() {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} id="como-funciona">
            <div>
                <img width={500} src={imagem} />
            </div>
            <div className='texts' style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="texto-esquerda">
                    <a>Soluções com </a>Inteligência Artificial
                    <h2>Na Fraktal Softwares, temos orgulho de oferecer soluções abrangentes de <strong>Inteligência Artificial (IA).</strong> Não apenas desenvolvemos IA, mas também a implementamos nos sistemas e aplicativos dos nossos clientes, proporcionando um nível superior de inovação e eficiência.</h2>
                </div>
                <div className="texto-direita">
                    <div>
                        <h2><strong>Inovação com Inteligência Artificial:</strong> A Fraktal Softwares alia o fine tuning da OpenAI e Chat GPT para criar soluções personalizadas, elevando a eficiência dos negócios dos nossos clientes.</h2>
                    </div>
                    <div>
                        <h2><strong>Customização Avançada:</strong> Utilizamos a tecnologia OpenAI para desenvolver sistemas que se adaptam às necessidades únicas de cada cliente, garantindo experiências exclusivas.</h2>
                    </div>
                    <div>
                        <h2><strong>Agilidade e Precisão:</strong> Integrando o Chat GPT, oferecemos interações rápidas e precisas, acelerando a tomada de decisões e otimizando os resultados dos clientes.</h2>
                    </div>
                    <div>
                        <h2><strong>Vanguarda Tecnológica:</strong> Com o fine tuning da OpenAI, a Fraktal Softwares se mantém na vanguarda do mercado, antecipando tendências e superando as expectativas dos clientes.</h2>
                    </div>
                </div>
            </div>
            <div className="processo-de-criacao">
                <div className="processo-de-criacao-texto">
                    <h1>O nosso processo de criação</h1>
                    <h2>Ao contrário do desenvolvimento tradicional, com a Fraktal você pode rapidamente projetar, desenvolver, lançar e escalar a sua ideia.</h2>
                </div>
                <div className='processo-de-criacao-boxes' style={{ display: "flex" }}>
                    <div className="processo-de-criacao-infos" style={{ marginLeft: 15 }}>
                        <EmojiObjectsOutlinedIcon style={{ color: 'white', padding: '20px' }} sx={{ fontSize: 80, backgroundColor: '#4d566c', borderRadius: 50 }} />
                        <h1>Briefing</h1>
                        <h2>Este é o ponto de partida, em que coletamos informações cruciais do projeto, como objetivos, requisitos e restrições. Isso cria a base sólida para o desenvolvimento.</h2>
                    </div>
                    <div className="processo-de-criacao-infos">
                        <DrawOutlinedIcon style={{ color: 'white', padding: '20px' }} sx={{ fontSize: 80, backgroundColor: '#019875', borderRadius: 50 }} />
                        <h1>Prototipagem</h1>
                        <h2>Em seguida, prototipamos criando uma versão preliminar do sistema para validar conceitos e obter feedback inicial. Isso ajuda a ajustar o curso antes do desenvolvimento completo.</h2>
                    </div>
                    <div className="processo-de-criacao-infos">
                        <CodeIcon style={{ color: 'white', padding: '20px' }} sx={{ fontSize: 80, backgroundColor: '#001f3a', borderRadius: 50 }} />
                        <h1>Desenvolvimento</h1>
                        <h2>Nessa etapa, construímos o sistema conforme os requisitos, com codificação, integração e implementação de recursos, garantindo colaboração eficiente da equipe.</h2>
                    </div>
                    <div className="processo-de-criacao-infos">
                        <VerifiedOutlinedIcon style={{ color: 'white', padding: '20px' }} sx={{ fontSize: 80, backgroundColor: '#019875', borderRadius: 50 }} />
                        <h1>QA</h1>
                        <h2>Antes do lançamento, passamos pela QA, em que testamos o sistema, corrigimos bugs, avaliamos o desempenho e garantimos conformidade com os padrões de qualidade.</h2>
                    </div>
                    <div className="processo-de-criacao-infos">
                        <AppSettingsAltOutlinedIcon style={{ color: 'white', padding: '20px' }} sx={{ fontSize: 80, backgroundColor: '#4d566c', borderRadius: 50 }} />
                        <h1>Deploy</h1>
                        <h2>Com a aprovação da QA, implantamos o sistema no ambiente de produção, assegurando uma transição suave do desenvolvimento para o uso real.</h2>
                    </div>
                </div>
                <div>
                    <Button className="saia-na-frente" href='https://wa.me/+558381581045' target="_blank" style={{ textTransform: 'none' }} variant="contained">Saia na Frente!</Button>
                </div>
            </div>
        </div>
    );
}

export default Body3;
