import React from 'react';
import './Body1.css';
import { Button } from '@mui/material';
import imagem from './images/fraktal-1-1024x1010.jpg.webp';
import imagem2 from './images/fraktal-2-1024x1024.jpg.webp';
import logoacutis from './images/f1.png';


function Body1() {
    return (
        <div id="sobre" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginTop: 125, paddingBottom: 50 }}>
            <div className='div-text' style={{ display: 'flex' }}>
                <div className="texto-principal-body">
                    <h1>A primeira Software House do mercado com o modelo <a>"Cozinha Aberta" !</a></h1>
                    <h2>Você no controle total do processo de desenvolvimento <a>24 horas</a> por dia!</h2>
                    <h3>Já pediu um prato no restaurante e ficou em dúvida em como ele está sendo preparado? Isso também acontece no mundo da tecnologia.</h3>
                    <h3>Inspirado pelo conceito de "cozinha aberta" em restaurantes, proporcionamos aos nossos clientes uma visão clara e em tempo real de cada etapa do desenvolvimento do seu projeto.</h3>
                    <Button className="agende-uma-reuniao" href='https://wa.me/+558381581045' target="_blank" style={{ textTransform: 'none' }} variant="contained">Agende uma Reunião</Button>
                </div>
                <div className='div-img' style={{ marginLeft: 100 }}>
                    <img src={logoacutis} width={500} />
                </div>
            </div>
            <div className='div-text2' style={{ display: 'flex', marginTop: 100, marginRight: 150 }}>
                <div className='div-img2' style={{ marginLeft: 100 }}>
                    <img src={imagem2} width={550} />
                </div>
                <div className="texto-principal-body" style={{ cursor: 'default'  }}>
                    <div className="box">
                        <h1>Transparência total</h1>
                        <h2>Acompanhe o progresso do seu projeto em tempo real, como nunca antes.</h2>
                    </div>
                    <div className="box">
                        <h1>Aprovação de Etapas</h1>
                        <h2>Aprove ou sugira mudanças diretamente no aplicativo, simplificando o processo.</h2>
                    </div>
                    <div className="box">
                        <h1>Gestão de múltiplos projetos</h1>
                        <h2>Se você tem mais de um projeto conosco, o Cozinha Aberta os centraliza em um único lugar.</h2>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Body1;
