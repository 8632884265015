import React, { useState } from 'react';
import './Slider.css'; 
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';

const Slider = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0); 

    const nextSlide = () => {
        const newIndex = (currentIndex + 1) % (images.length - 1); 
        setCurrentIndex(newIndex);
    };

    const prevSlide = () => {
        const newIndex = (currentIndex - 1 + images.length - 1) % (images.length - 1);
        setCurrentIndex(newIndex);
    };

    return (
        <div className="slider-container">
            <ArrowBackIosNewOutlinedIcon style={{marginRight: 25}} onClick={prevSlide} />
            <div className="prev-slide">
                <img src={images[currentIndex === 0 ? images.length - 2 : currentIndex - 1]} alt={`Slide ${currentIndex === 0 ? images.length - 2 : currentIndex - 1}`} className="prev-img" />
            </div>
            <div className="main-slide">
                <img src={images[currentIndex]} alt={`Slide ${currentIndex}`} className="main-img" />
            </div>
            <div className="next-slide">
                <img src={images[currentIndex === images.length - 1 ? 1 : currentIndex + 1]} alt={`Slide ${currentIndex === images.length - 1 ? 1 : currentIndex + 1}`} className="next-img" />
            </div>
            <ArrowForwardIosOutlinedIcon style={{marginLeft: 25}} onClick={nextSlide}/>
        </div>
    );
};

export default Slider;
