import React from 'react';
import './Body2.css';
import { Button, Card, CardContent, Typography } from '@mui/material';
import imagem from './images/3.png';


function Body2() {
    return (
        <div id="servicos" style={{ backgroundColor: '#001f3a', paddingBottom: 80, paddingTop: 50 }}>
            <div className="body2-principal" style={{ display: 'flex' }}>
                <div className='img-blocks' style={{ marginTop: 25, marginLeft: 15 }}>
                    <img src={imagem} width={350} />
                </div>
                <div className='div-o-que-fazemos' style={{ backgroundColor: '#001f3a', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div className="o-que-fazemos">
                        <h1>O que <a>Fazemos</a></h1>
                        <h2>É para quem tem pressa de ser um sucesso exponencial.<br></br>
                            Facilite os seus processos, economize tempo, diminua seus custos e aumente a sua lucratividade com a <strong>Fraktal.</strong></h2>
                        <Button className="entre-em-contato" href='https://wa.me/+558381581045' target="_blank" style={{ textTransform: 'none' }} variant="contained">Entre em contato</Button>
                    </div>
                    <div className="caixas1">
                        <div>
                            <div className='caixas2'>
                                <Card sx={{ width: 350 }} className="card2">
                                    <CardContent>
                                        <Typography variant="h5" component="div" style={{ fontWeight: 'bolder' }}>
                                            Automação
                                        </Typography>
                                        <Typography variant="body2" className="card-descricao2">
                                            Acabe com as planilhas e com dezenas de sistemas diferentes em telas distintas, através de uma solução de automação sob medida!
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </div>
                            <div className='mini-container-caixas' style={{ marginTop: 50 }}>
                                <Card sx={{ width: 350, borderBottomColor: '#006598' }} className="card2">
                                    <CardContent>
                                        <Typography variant="h5" component="div" style={{ fontWeight: 'bolder' }}>
                                            Web App
                                        </Typography>
                                        <Typography variant="body2" className="card-descricao2">
                                            O seu MVP em até 90 dias, com tecnologias PWA e compatibilidade infinita com todos os dispositivos do mercado!
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </div>
                        </div>
                        <div className='container-caixas' style={{ marginTop: 100 }}>
                            <div>
                                <Card sx={{ width: 350, marginRight: 0, borderBottomColor: '#99ccff' }} className="card2">
                                    <CardContent>
                                        <Typography variant="h5" component="div" style={{ fontWeight: 'bolder' }}>
                                            Mobile App
                                        </Typography>
                                        <Typography variant="body2" className="card-descricao2">
                                            Acelere o lançamento do seu projeto com a Fraktal, desenvolvemos soluções mobile nativas em react native com alta performance!
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </div>
                            <div className='mini-container-caixas' style={{ marginTop: 50 }}>
                                <Card sx={{ width: 350, marginRight: 0, borderBottomColor: '#99ccff' }} className="card2">
                                    <CardContent>
                                        <Typography variant="h5" component="div" style={{ fontWeight: 'bolder' }}>
                                            E muito mais
                                        </Typography>
                                        <Typography variant="body2" className="card-descricao2">
                                            Para cada situação uma solução, ferramentas de gestão, hubs de dashboards gerenciais, gestores de documentos, check-lists inteligentes e muito mais!
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='img-blocks' style={{ alignSelf: 'flex-end', marginLeft: 30 }}>
                    <img src={imagem} width={350} />
                </div>
            </div>
        </div>
    );
}

export default Body2;
