import React from 'react';
import './Footer.css';
import gif from './images/giphy.gif';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button } from '@mui/material';

function Footer() {
    return (
        <div id="contato" style={{ display: 'flex' }}>
            <div className="footer-img">
                <img src={gif} width={600} />
            </div>
            <div className='footer-principal'>
                <div className="footer1-texto">
                    <h1>Entre em <a>contato</a></h1>
                    <h2>Se você está aqui, é porque tem uma ideia inovadora que merece sair do papel. Confira como podemos te ajudar:</h2>
                    <h3 style={{ display: 'flex', alignItems: 'center' }} ><CheckCircleIcon /> 300% mais rápido para lançar</h3>
                    <h3 style={{ display: 'flex', alignItems: 'center' }}><CheckCircleIcon /> 100% dentro do seu orçamento</h3>
                    <h3 style={{ display: 'flex', alignItems: 'center' }}><CheckCircleIcon /> 80% menos custos de sustentação</h3>
                    <h4>Tire já sua ideia do papel!</h4>
                    <Button className="agende-uma-reuniao1" href='https://wa.me/+558381581045' target="_blank" style={{ textTransform: 'none' }} variant="contained">Agende uma reunião</Button>
                </div>
            </div>
        </div>
    );
}

export default Footer;
